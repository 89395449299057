// Services.js
import React from "react";
import "./Services.css";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";

const servicesData = [
  { title: "Inverter Installation", description: "Reliable installation with optimal configuration for seamless power." },
  { title: "Maintenance & Repair", description: "Regular servicing and prompt repairs to keep systems running efficiently." },
  { title: "Energy Consultation", description: "Tailored advice on energy-saving practices and solar integration." },
  { title: "Solar Panel Installation", description: "Custom solar setups to complement inverter systems and reduce energy bills." },
  { title: "Battery Replacement", description: "High-quality battery replacement for uninterrupted power." },
  { title: "Electrical Wiring", description: "Safe and efficient wiring solutions for residential and commercial setups." },
];

const Services = () => {
  return (
    <section className="services-container">
      <Link to={"https://wa.me/2348038588959"} className="contact-service">Chat with us <FaWhatsapp/></Link>
      <h2 className="services-title">Our Services</h2>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div key={index} className="service-card">
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
