// HeroSection.js
import React from "react";
import "./Hero.css";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="hero-container">
      <div className="hero-content">
        <h1 className="hero-title">
          Powering a Sustainable Future with <br /><span>BS OLUSHOLA ELECT & ENERGY SOLUTION</span>
        </h1>
        <p className="hero-subtitle">
          Reliable, efficient, and eco-friendly inverters designed to keep your energy running.
        </p>

        <Link to={"learn"}>
         <button className="hero-button">
           Learn More <FaArrowRight className="arrow-icon" />
         </button>
        </Link>
      </div>
      <div className="hero-bg-animation">
        {/* Background gradient animation */}
      </div>
    </div>
  );
};

export default Hero;
