import { FaLongArrowAltUp, FaPhoneAlt } from 'react-icons/fa';
import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/nav/Footer';
import Navbar from './components/nav/Navbar';
import ScrollToTop from './components/ScrollToTop';
import Services from './components/Services';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { IoLogoWhatsapp } from 'react-icons/io';
import { useEffect, useState } from 'react';
import LearnMore from './components/LearnMore';
import SingleProduct from './components/products/SingleProduct';
import ProductList from './components/products/ProductList';
import Login from './components/admin/Login';
import AdminDashboard from './components/admin/AdminDashboard';
import { AuthProvider, useAuth } from './AuthContext';

function App() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll to top functionality
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="App">
      <AuthProvider>
      <Router>
        <ScrollToTop />
        <a href="tel:+2348038588959">
          <div className="side-box">
            <FaPhoneAlt />
          </div>
        </a>
        <a href="https://wa.me/2348038588959">
          <div className="side-box2">
            <IoLogoWhatsapp />
          </div>
        </a>

        {/* Scroll to Top Button */}
        {showScrollButton && (
          <button className="scroll-to-top" onClick={scrollToTop}>
            <FaLongArrowAltUp />
          </button>
        )}
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/learn" element={<LearnMore />} />
          <Route path="/products/:id" element={<SingleProduct />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/learn" element={<LearnMore />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<PrivateRoute><AdminDashboard /></PrivateRoute>} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/:id" element={<SingleProduct />} />

        </Routes>
        <Footer />
      </Router>
      </AuthProvider>
    </div>
  );
};

// Private route to protect certain pages
const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/" />;
};

export default App;
