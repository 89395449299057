import React from "react";
import "./Hero2.css";

const Hero2 = () => {
  return (
    <main className="home-container">
      {/* First Advert Section */}
      <section className="advert-section">
        <div className="advert-image image-left">
          <img src="https://images.pexels.com/photos/9875408/pexels-photo-9875408.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Advert 1" />
        </div>
        <div className="advert-text text-right">
          <h2>Top Quality Inverters</h2>
          <p>
            Experience reliable and sustainable energy solutions with our range
            of high-performance inverters and power systems. Designed for efficiency
            and resilience, our products are engineered to meet diverse energy demands,
            from residential to large-scale industrial applications.
          </p>
        </div>
      </section>

      {/* Second Advert Section */}
      <section className="advert-section advert-middle">
        <div className="advert-text text-left">
          <h2>Eco-Friendly Solutions</h2>
          <p>
            Power your world sustainably with our eco-friendly energy solutions, crafted to provide 
            clean, renewable energy for homes, businesses, and communities. From solar installations 
            to energy storage, our innovations help reduce environmental impact and foster a greener future.
          </p>
        </div>
        <div className="advert-image image-right">
          <img src="https://images.pexels.com/photos/577514/pexels-photo-577514.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Advert 2" />
        </div>
      </section>

      {/* Third Advert Section */}
      <section className="advert-section">
        <div className="advert-image image-left">
          <img src="https://images.pexels.com/photos/9875683/pexels-photo-9875683.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Advert 3" />
        </div>
        <div className="advert-text text-right">
          <h2>Advanced Energy Solutions</h2>
          <p>
            Discover total energy independence with our advanced solutions, offering seamless, uninterrupted power.
            Our systems, including backup power and grid integration, are optimized for all environments. Experience 
            reduced energy costs and long-lasting reliability, ensuring peace of mind with energy solutions that adapt to your needs.
          </p>
        </div>
      </section>
    </main>
  );
};

export default Hero2;
