// AddProductForm.js
import React, { useState, useRef } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./AddProductForm.css";

const AddProductForm = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageUpload = async (e) => {
    e.preventDefault();
    if (!image) return;

    try {
      const imageRef = ref(storage, `products/${image.name}`);
      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);

      await addDoc(collection(db, "products"), {
        name,
        description,
        price: parseFloat(price),
        imageUrl, // Save as imageUrl to match ProductList expectations
      });

      // Alert success and clear fields
      alert("Product added successfully!");

      // Clear fields
      setName("");
      setDescription("");
      setPrice("");
      setImage(null);
      fileInputRef.current.value = "";
    } catch (error) {
      alert("Error adding product: " + error.message);
    }
  };

  return (
    <form onSubmit={handleImageUpload} className="add-product-form">
      <h3>Add Product</h3>
      <input
        type="text"
        placeholder="Product Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <textarea
        placeholder="Product Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        required
      />
      <input
        type="number"
        placeholder="Product Price"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        required
      />
      <input
        type="file"
        ref={fileInputRef}
        onChange={(e) => setImage(e.target.files[0])}
        required
      />
      <button type="submit">Add Product</button>
    </form>
  );
};

export default AddProductForm;
