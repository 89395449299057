// Advert.js
import React from "react";
import "./Advert.css";

const Advert = () => {
  return (
    <section className="advert-container">
      <div className="advert-box box1">
        <h3>Reliable Power</h3>
        <p>High-performance inverters to keep you powered all day.</p>
      </div>
      <div className="advert-box box2">
        <h3>Eco-Friendly</h3>
        <p>Our inverters are designed to reduce environmental impact.</p>
      </div>
      <div className="advert-box box3">
        <h3>Cost-Effective</h3>
        <p>Save on electricity bills with our energy-efficient solutions.</p>
      </div>
      <div className="advert-box box4">
        <h3>24/7 Support</h3>
        <p>Our team is here for you around the clock, every day.</p>
      </div>
    </section>
  );
};

export default Advert;
