// About.js
import React from "react";
import "./About.css";

const About = () => {
  return (
    <section className="about-container">
      <h2 className="about-title">About BS Olushola <br />Elect and Energy Solution</h2>
      <p className="about-description">
        BS Olushola Elect and Energy Solution specializes in providing a wide range of sustainable, reliable energy solutions. From high-performance inverters to full electrical installations, we are committed to delivering energy independence with an eco-friendly and cost-effective approach. Our team is dedicated to ensuring seamless, uninterrupted power solutions tailored to meet the unique needs of our clients.
      </p>
      
      {/* Mission and Vision Section */}
      <div className="about-cards">
        <div className="about-card">
          <h3>Our Mission</h3>
          <p>To deliver innovative energy solutions that empower clients to embrace clean, renewable energy for a more sustainable future.</p>
        </div>
        <div className="about-card">
          <h3>Our Vision</h3>
          <p>To lead in renewable energy, providing cutting-edge solutions that make sustainable power accessible to everyone.</p>
        </div>
      </div>
      
      {/* CEO Section */}
      <div className="about-ceo">
        <h3>Meet Our CEO</h3>
        <div className="ceo-info">
          <img src="/images/ceo.png" alt="CEO of BS Olushola Electrical and Energy Solution" className="ceo-image" />
          <div className="ceo-description">
            <h4>Mr. Olushola</h4>
            <p>
              With over a decade of experience in the energy industry, Mr. Olushola has been at the forefront of promoting renewable and efficient energy solutions. His vision is to make reliable and sustainable power accessible to all, while innovating in cleaner and greener energy solutions.
            </p>
          </div>
        </div>
      </div>
      
      {/* Team Section */}
      <div className="about-team">
        <h3>Meet Our Team</h3>
        <div className="team-grid">
          <div className="team-member">
            <img src="/images/t1.png" alt="Team Member 1" className="team-image" />
            <h4>Engineer Michael Adebayo</h4>
            <p>Chief Engineer, specializing in renewable energy installations and inverter technology.</p>
          </div>
          <div className="team-member">
            <img src="/images/t3.png" alt="Team Member 2" className="team-image" />
            <h4>Mr. James Olayemi</h4>
            <p>Lead Technician, expert in olar and inverter repair and solar panel installations.</p>
          </div>
          <div className="team-member">
            <img src="/images/t2.png" alt="Team Member 3" className="team-image" />
            <h4>Ms. Anita Janet</h4>
            <p>Customer Relations Manager, ensuring a seamless and satisfying experience for our clients.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
