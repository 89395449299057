import { Helmet } from "react-helmet";

const HelmetSEO = () => (
  <Helmet>
    {/* Primary Meta Tags */}
    <title>BS Olushola Energy | Reliable Solar and Inverter Solutions</title>
    <meta name="title" content="BS Olushola Energy | Reliable Solar and Inverter Solutions" />
    <meta name="description" content="BS Olushola Energy offers sustainable and efficient solar and inverter solutions for reliable energy. Learn more about our energy solutions tailored to meet your power needs." />
    <meta name="keywords" content="BS Olushola Energy, solar solutions, inverter installation, sustainable energy, renewable energy solutions, power solutions Nigeria, solar and inverter in lagos, solar in lagos, inverter company in lagos, best inverter company in lagos, inverter company in Nigeria, Energy solution in Nigeria, solar company in lagos" />
    <meta name="robots" content="index, follow" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />

    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.bsolusholaenergy.ng/" />
    <meta property="og:title" content="BS Olushola Energy | Reliable Solar and Inverter Solutions" />
    <meta property="og:description" content="BS Olushola Energy provides sustainable and efficient solar and inverter solutions to keep your energy reliable. Visit us to discover the right solution for your energy needs." />
    <meta property="og:image" content="%PUBLIC_URL%/images/logo2.png" />

    {/* Twitter */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://www.bsolusholaenergy.ng/" />
    <meta property="twitter:title" content="BS Olushola Energy | Reliable Solar and Inverter Solutions" />
    <meta property="twitter:description" content="Discover BS Olushola Energy's sustainable solar and inverter solutions for reliable energy." />
    <meta property="twitter:image" content="%PUBLIC_URL%/images/logo2.png" />

    {/* Icons and Favicon */}
    <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
    <link rel="apple-touch-icon" href="%PUBLIC_URL%/images/logo2.png" />
    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />

    {/* Fonts */}
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
  </Helmet>
);

export default HelmetSEO;
