// LearnMore.js
import React from "react";
import { FaBatteryFull, FaBolt, FaLeaf, FaTools } from "react-icons/fa";
import "./LearnMore.css";

const LearnMore = () => {
  return (
    <div className="learnmore-container">
      <header className="learnmore-header">
        <h1>About BS Olushola Elect & Energy Solution</h1>
        <p>Empowering homes and businesses with sustainable, reliable, and efficient energy solutions.</p>
      </header>

      {/* About Section */}
      <section className="learnmore-section">
        <div className="section-content">
          <h2>Our Mission</h2>
          <p>At BS Olushola Elect & Energy Solution, we are committed to a sustainable energy future. Our inverters and power solutions provide efficient and eco-friendly power management, ensuring that your energy stays on without compromise.</p>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="learnmore-section why-choose">
        <div className="section-content">
          <h2>Why Choose Us?</h2>
          <div className="choose-us-content">
            <div className="choose-card">
              <FaBatteryFull className="choose-icon" />
              <h3>Long-Lasting Power</h3>
              <p>Our inverters are designed to offer consistent, long-lasting power. Built to last, they ensure that you remain connected during outages.</p>
            </div>
            <div className="choose-card">
              <FaBolt className="choose-icon" />
              <h3>Efficiency at its Core</h3>
              <p>Every product we design optimizes energy consumption, reducing costs and providing efficient power solutions for all settings.</p>
            </div>
            <div className="choose-card">
              <FaLeaf className="choose-icon" />
              <h3>Eco-Friendly Solutions</h3>
              <p>Our commitment to green energy solutions allows you to enjoy clean power that reduces environmental impact.</p>
            </div>
            <div className="choose-card">
              <FaTools className="choose-icon" />
              <h3>Exceptional Support</h3>
              <p>Our team of experts is here to provide reliable support and guidance for all your energy needs.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Our Products Section */}
      <section className="learnmore-section our-products">
        <div className="section-content">
          <h2>Our Products</h2>
          <p>Explore our range of cutting-edge inverters and power management systems designed for every need.</p>
          <div className="product-grid">
            <div className="product-card">
              <img src="images/inv2.png" alt="Inverter 1" className="product-image" />
              <h3>SmartPower Inverter</h3>
              <p>A high-efficiency inverter suitable for homes and small businesses, offering seamless power backup.</p>
            </div>
            <div className="product-card">
              <img src="images/inv1.png" alt="Inverter 2" className="product-image" />
              <h3>EcoGen Inverter</h3>
              <p>Built for sustainability, this model reduces power waste and maximizes energy use for eco-conscious users.</p>
            </div>
            <div className="product-card">
              <img src="images/inv3.png
              " alt="Inverter 3" className="product-image" />
              <h3>UltraPower Inverter</h3>
              <p>A powerful inverter designed for large businesses and industrial use, capable of handling high loads efficiently.</p>
            </div>
            <div className="product-card">
              <img src="images/inv4.png" alt="Inverter 4"         className="product-image" />
              <h3>SolarMax Inverter</h3>
              <p>A robust solar-compatible inverter optimized for homes and businesses looking to leverage solar energy for reliable and eco-friendly power.</p>
            </div>

          </div>
        </div>
      </section>

      {/* Environmental Impact Section */}
      <section className="learnmore-section impact-section">
        <div className="section-content">
          <h2>Our Environmental Impact</h2>
          <p>We strive to reduce carbon emissions by designing solutions that promote renewable energy use, energy savings, and a greener planet.</p>
        </div>
      </section>
    </div>
  );
};

export default LearnMore;
