// Dashboard.js
import React, { useEffect, useState } from "react";
import { collection, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../AuthContext";
import AddProductForm from "./AddProductForm";
import "./AdminDashboard.css"
import Register from "./Register";

const AdminDashboard = () => {
  const { logout } = useAuth();
  const [products, setProducts] = useState([]);
  const productsCollectionRef = collection(db, "products");
  const [showRegister, setShowRegister] = useState(false);

  const fetchProducts = async () => {
    const data = await getDocs(productsCollectionRef);
    setProducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "products", id));
    setProducts(products.filter((product) => product.id !== id));
  };

  const handleUpdate = async (id, updatedData) => {
    const productDoc = doc(db, "products", id);
    await updateDoc(productDoc, updatedData);
    setProducts(products.map((product) => (product.id === id ? { ...product, ...updatedData } : product)));
  };
  

  return (
    <div className="dashboard">
      <button onClick={logout}>Logout</button>
      <h2>Admin Dashboard</h2>
      <AddProductForm onProductAdded={fetchProducts} />
      <div className="product-list">
        {products.map((product) => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.name} />
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <button onClick={() => handleDelete(product.id)}>Delete</button>
            <button onClick={() => handleUpdate(product.id, { name: "Updated Name" })}>Edit</button>
          </div>
        ))}
      </div>
      
            {/* Conditionally render AddProductForm */}
        <button onClick={() => setShowRegister(!showRegister)}>
          {showRegister ? 'Close Register' : 'Add a New Admin'}
        </button>
        {showRegister && <Register />}

    </div>
  );
};

export default AdminDashboard;
