// Contact.js
import React from "react";
import "./Contact.css";
import { FaPhoneAlt, FaEnvelope, FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

const Contact = () => {
  return (
    <section className="contact-container">
      <h2 className="contact-title">Get in Touch</h2>
      
      <div className="contact-content">
        {/* Contact Information Section */}
        <div className="contact-info">
          <h3>Contact Information</h3>
          <p><FaPhoneAlt /> Phone: +234 803 858 8959</p>
          <p><FaEnvelope /> Email: info@bsolusholaenergy.ng</p>
          <p>Address: 16 Carter St, Adekunle, Lagos, Nigeria</p>
          <h3>Office Hours</h3>
          <p>Mon - Fri: 9:00 AM - 6:00 PM</p>
          <p>Sat - Sun: Closed</p>
          
          {/* Social Media Links */}
          <div className="contact-socials">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          </div>
        </div>
        
        {/* Contact Form */}
        <form className="contact-form">
          <input type="text" placeholder="Your Name" required />
          <input type="email" placeholder="Your Email" required />
          <textarea placeholder="Your Message" rows="5" required></textarea>
          <button type="submit" className="contact-submit">Send Message</button>
        </form>
        
        {/* Google Map */}
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.242523878148!2d3.3798112749926683!3d6.490944893501104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8c8647d19d81%3A0xb7d6841e0c1633ec!2s16%20Carter%20St%2C%20Adekunle%2C%20Lagos%20101245%2C%20Lagos!5e0!3m2!1sen!2sng!4v1729857805238!5m2!1sen!2sng"
            width="100%"
            height="400"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            title="Company Location Map"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Contact;
