import React from 'react'
import "./Home.css"
import Hero from '../components/Hero'
import Advert from '../components/Advert'
import Hero2 from '../components/Hero2'
import Counter from '../components/Counter'
import HelmetSEO from '../components/HelmetSEO'

const Home = () => {
  return (
    <div className='home'>
       <HelmetSEO/>
       <Hero/>
       <Advert/>
       <Hero2/>
       <Counter/>
    </div>
  )
}

export default Home
