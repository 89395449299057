// Footer.js
import React from "react";
import "./Footer.css";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-about">
          <h2>BS Olushola Elect & Energy Solution</h2>
          <p>
            Leading provider of sustainable energy solutions. We specialize in
            high-quality inverters to keep your power running efficiently and
            reliably.
          </p>
        </div>

        <div className="footer-links">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>

        <div className="footer-services">
          <h3>Our Services</h3>
          <ul>
            <li><a href="#solar-panels">Solar Panels</a></li>
            <li><a href="#installations">Installations</a></li>
            <li><a href="#maintenance">Maintenance</a></li>
            <li><a href="#consultation">Consultation</a></li>
            <li><Link to="/login"  className="admin-login">Login</Link></li>
          </ul>
        </div>

        <div className="footer-social">
          <h3>Connect with Us</h3>
          <div className="social-icons">
            <a href="https://facebook.com"><FaFacebook /></a>
            <a href="https://twitter.com"><FaTwitter /></a>
            <a href="https://instagram.com"><FaInstagram /></a>
            <a href="https://linkedin.com"><FaLinkedin /></a>
            <a href="https://www.tiktok.com/@bolajokoolushola?_t=8qtdhamhfGP&_r=1"><FaTiktok /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} BS Olushola . All Rights Reserved. <br /> <Link to="https://www.oguntoyinbo.com.ng" className="my-link">Multibrand</Link></p>
      </div>
    </footer>
  );
};

export default Footer;
