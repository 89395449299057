import React, { useEffect, useState, useRef } from 'react';
import './Counter.css';

const Counter = () => {
  const CountUp = ({ endValue, duration }) => {
    const [count, setCount] = useState(0);
    const counterRef = useRef(null);
    const hasCounted = useRef(false);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && !hasCounted.current) {
            startCounting();
            hasCounted.current = true; // Ensure counting starts only once
          }
        },
        { threshold: 0.5 }
      );

      if (counterRef.current) {
        observer.observe(counterRef.current);
      }

      return () => {
        if (counterRef.current) {
          observer.unobserve(counterRef.current);
        }
      };
    }, []);

    const startCounting = () => {
      let incrementValue = Math.ceil((endValue - count) / 50);
      let interval = duration / 50;

      const counter = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount + incrementValue >= endValue) {
            clearInterval(counter);
            return endValue;
          }
          return prevCount + incrementValue;
        });
      }, interval);
    };

    return (
      <h3 ref={counterRef}>{count}+</h3>
    );
  };

  return (
    <section className="counter-section">
      <h2>Our Achievements</h2>
      <div className="counter-grid">
        <div className="counter-item">
          <CountUp endValue={5000} duration={1500} />
          <p>5,000+ satisfied clients now enjoy sustainable and  reliable power solutions.</p>
        </div>
        <div className="counter-item">
          <CountUp endValue={2500} duration={1500} />
          <p>Over 2,500 successful solar and inverter installations completed nationwide.</p>
        </div>
        <div className="counter-item">
          <CountUp endValue={300} duration={1500} />
          <p>300+ certified technicians delivering top-notch service and expertise.</p>
        </div>
        <div className="counter-item">
          <CountUp endValue={15000} duration={1500} />
          <p>More than 15,000 hours dedicated to clean energy solutions and client support.</p>
        </div>
      </div>
    </section>
  );
};

export default Counter;
